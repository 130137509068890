<template>
  <div class="text-right">
  </div>
</template>

<script>
import $ from "jquery";
import { Toast } from "vant";
import axios from "axios";
import Cookies from 'js-cookie';
export default {
  name: "App",
  components: {
    // HelloWorld
  },
  data() {
    return {
      userinfodata: null,
      total: null,
      mine_admin: '',
      theRequest: [],
      headers: {
        deviceId: '',
        lang: '',
        version: '',
        os: '',
        model: '',
        fid: ''
      },
      token: null,
      uid: null,
      headers: null,
      userInfo: [],
      invitationContributionConfigList: [],
      pocketCoinWithdrawRate: null,
    };
  },
  created() {
  },
  mounted() {
    this.$completes.complete("getUserInfo");
    window.getUserInfo = this.getUserInfo
    window['getUserInfo'] = (data) => {
      this.getUserInfo(data);
    }
    // this.helplist()
    // this.$i18n.locale = 'ar'
    document.getElementsByTagName("html")[0].style.backgroundColor = "#8260FF";
  },

  methods: {
    //返回
    returns() {
      this.$router.go(-1)
    },

    getUserInfo(data) {
      let that = this
      this.userInfo = JSON.parse(data);
      this.headers = [
        'Content-Type:' + 'application/json; charset=utf-8',
        'deviceId:' + JSON.parse(data).deviceId,
        'lang:' + JSON.parse(data).lang,
        'version:' + JSON.parse(data).version,
        'os:' + JSON.parse(data).os,
        'model:' + JSON.parse(data).model,
        'fid:' + JSON.parse(data).fid
      ]
      this.token = JSON.parse(data).token
      this.uid = JSON.parse(data).userId
      // this.serviceIp = JSON.parse(data).serviceIp
      let langs = this.userInfo.lang.substring(0, 2)
      let lang = this.userInfo.lang
      if (langs == 'zh') {
        if (lang == 'zh_TW' || lang == 'zh_HK') {
          this.$i18n.locale = 'tc'
        }
        else {
          this.$i18n.locale = 'zh'
        }
      }
      else if (langs == 'en') {
        this.$i18n.locale = 'en'
      }
      else if (langs == 'ar') {
        this.$i18n.locale = 'ar'
      }
      else if (langs == 'tr') {
        this.$i18n.locale = 'tr'
      }
      else if (langs == 'pt') {
        this.$i18n.locale = 'pt'
      }
      else if (langs == 'es') {
        this.$i18n.locale = 'es'
      }
      else {
        this.$i18n.locale = 'en'
      }
      document.title = this.$t('inviteCashDescription.title')
      document.querySelector('html').setAttribute('lang', this.$i18n.locale);
      this.helplist()
    },

    helplist() {
      let that = this
      let paramObjs = {
        option: 'get',
        host: this.$serviceIp + 'api/user/invitation/getInvitationContributionConfig?token=' + that.token + '&uid=' + that.uid,
        data: '',
        header: JSON.stringify(that.headers),
      };
      this.$server.curlExec(paramObjs).then((data) => {
        if (data.code == 200) {
          that.invitationContributionConfigList = data.data.invitationContributionConfigList
          if (data.data.pocketCoinWithdrawRate != undefined) {
            that.pocketCoinWithdrawRate = data.data.pocketCoinWithdrawRate
          }
        }
      });
    },
    //copy code
    copyThat(data) {
      let url = data;
      let oInput = document.createElement('input');
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      document.execCommand("Copy"); // 执行浏览器复制命令
      Toast('Copy successfully ')
      oInput.remove()
    },
    // //原生调用方法

  },

};

</script>

<style>
/* html[lang="ar"] .text-right {
  text-align: right;
}

html[lang="zh"] .text-left {
  text-align: left;
} */

/* .taskbox {
    left: 0;
    right: 0;
  } */

html[lang="ar"] .text-right {
  direction: rtl;
}

html[lang="ar"] .returns {
  -webkit-transform: rotateY(180deg);
}

.head {
  display: flex;
  align-items: center;
  background: #8260ff;
  justify-content: center;
  padding: 0 0.27rem;
  height: 44px;
  margin: 0 !important;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 999;
  top: 0;
  padding-top: 44px;
}

.boxs {
  position: absolute;
  left: 0;
  right: 0;
  font-family: Regular;
  margin-top: 88px;
  background: #8260ff;
  padding: 0.27rem;
}

.pagetitle {
  font-size: 0.3rem;
  font-family: Regular;
  color: #ffffff;
  line-height: 0.5rem;
  flex: 1;
  text-align: center;
}

.returns {
  width: 0.4rem;
  height: 0.4rem;
}

.help {
  width: 0.47rem;
  height: 0.47rem;
}

.dtitle {
  font-size: 0.28rem;
  color: #ffd14d;
  font-family: Semibolds;
  padding-bottom: 0.1rem;
}

.dcontent {
  font-size: 0.21rem;
  color: #ffffff;
  font-family: Regular;
  line-height: 0.35rem;
}

.benefits {
  margin-top: 0.25rem;
  color: #ffffff;
  background: #ffffff20;
  font-size: 0.28rem;
  padding: 0.18rem 0;
  text-align: center;
}

.biaotous {
  width: 100%;
  background: #ffffff10;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0.2rem 0;
}

.biaotous > .titles {
  width: 25%;
  text-align: center;
  font-size: 0.2rem;
  font-family: Regular;
  color: #ffd14d;
  padding: 0 0.1rem;
}

.familytitles {
  width: 18%;
  text-align: center;
  font-size: 0.2rem;
  font-family: Regular;
  color: #ffd14d;
  padding: 0 0.05rem;
  -webkit-text-size-adjust: none;
  max-height: 99999px;
}

.biaot_contents {
  padding: 0.3rem 0;
  margin: 0 0.1rem;
  border-top: 1px dashed rgba(255, 255, 255, 0.288);
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: space-around;

  font-size: 0.23rem;
  font-family: Regular;
  font-weight: 400;
  color: #ffffff;
}

.room {
  width: 30%;
  text-align: center;
  font-size: 0.2rem;
  color: #ffd14d;
}

.biaot_contentsvalue {
  text-align: center;
  width: 19%;
  font-size: 0.2rem;
}
</style>